<template>
  <v-card>
    <v-card-text>
      <v-switch
        aut-footer-toggle
        dense
        v-model="display"
        label="Display Footer"
      ></v-switch>
      <v-textarea
        v-if="display"
        :rows="1"
        auto-grow
        persistent-hint
        :hint="
          footerSecureContent
            ? 'Will be displayed in unauthenticated pages'
            : 'Will be displayed in all pages'
        "
        clearable
        aut-footer-insecure-content-input
        v-model="footerContent"
        label="Footer Unsecure HTML"
      />
      <v-textarea
        v-if="display"
        :rows="1"
        auto-grow
        persistent-hint
        hint="Will be displayed in authenticated pages"
        clearable
        aut-footer-secure-content-input
        v-model="footerSecureContent"
        label="Footer Secure HTML"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" aut-update-footer @click="updateSkin" class="ml-2"
        >Update</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
const debug = require("debug")("atman.components.settings.footer"); // eslint-disable-line
export default {
  name: "SettingsFooter",
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      footerContent: this.definition?.footer?.text?.content || "",
      footerSecureContent: this.definition?.footer?.text?.secure_content || "",
      display: this.definition?.footer?.display || false,
    };
  },
  computed: {},
  components: {},
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    updateSkin() {
      this.saveSettingsToDomain({
        footer: {
          text: {
            content: this.footerContent,
            secure_content: this.footerSecureContent,
          },
          display: this.display,
        },
      });
      this.displaySuccessMessage("Footer has been updated!");
    },
  },
};
</script>
